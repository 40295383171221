<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-data-table
            id="formatosTable"
            :headers="formatosHeader"
            :header-props="{ sortIcon: null }"
            class="elevation-1"
            :items="formatosPdfActas"
            :search="search"
            item-key="emailId"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="{
              'items-per-page-options': itemsPerPageOptions
            }"
            :items-per-page="itemPerPage"
          >
            <template v-slot:[`item.activo`]="{ item }">
              <v-icon v-if="item.activo" small color="primary">
                {{ check }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn color="primary" @click="newFormato" class="to-right">
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="editFormato(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar formato</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openDeleteFormat(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar formato</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <DeleteDialog
      :openDelete.sync="openDeleteDialog"
      @onDeleteItem="deleteFormat()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions, mapGetters } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ConfiguracionFormatosPdfActa",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    Ayuda
  },
  data: () => ({
    title: enums.titles.CONFIGURACION_FORMATOS_PDF_ACTAS,
    optionCode: enums.webSiteOptions.CONFIGURACION_FORMATOS_PDF_ACTA,
    check: enums.icons.CHECK_OUTLINE,
    searchIcon: enums.icons.SEARCH,
    itemsPerPageOptions: [10, 20, 30, 40, 50],
    itemPerPage: 10,
    openDeleteDialog: false,
    routeToGo: "FiscalizacionDeAportes",
    formatosPdfActas: [],
    search: "",
    formatoToDelete: {},
    sortDesc: true,
    codigo: "ACTAFISC",
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    formatosHeader: [
      {
        text: "Nombre",
        align: "start",
        value: "nombre"
      },
      { text: "Activo", value: "activo", align: "center" },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    showHelp: false,
    showExpand: false,
    showIcon: true
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadFormatos();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  computed: {
    ...mapGetters({}),
    sortBy: {
      get() {
        return this.sortByProceso;
      },
      set(newVal) {
        return newVal;
      }
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      fetchFormatosPdf: "configuracion/fetchFormatosPdf",
      deleteFormatoPdf: "configuracion/deleteFormatoPdf"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_FORMATO_PDF_ACTA:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_FORMATO_PDF_ACTA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_FORMATO_PDF_ACTA:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadFormatos() {
      const response = await this.fetchFormatosPdf(this.codigo);
      this.formatosPdfActas = response;
    },
    editFormato(item) {
      this.$router.push({
        name: "NuevoFormatoPdf",
        params: { formato: item }
      });
    },
    openDeleteFormat(item) {
      this.formatoToDelete = item;
      this.openDeleteDialog = true;
    },
    async deleteFormat() {
      const res = await this.deleteFormatoPdf(
        this.formatoToDelete.pdfTemplateId
      );
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadFormatos();
      }
      this.formatoToDelete = {};
    },
    newFormato() {
      this.$router.push({ name: "NuevoFormatoPdf" });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-toolbar__content {
  height: 40px !important;
}
</style>
